<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <div class="row">
                <div class="col-md-6 col-xl-4">
                  <div class="py-1">
                    <i class="fa fa-users fa-2x text-info"></i>
                    <h3 class="text-info">{{ totalDrivers }}</h3>
                    <p class="text-uppercase mb-1 font-13 font-weight-medium">
                      Total Drivers
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-xl-4">
                  <div class="py-1">
                    <i class="fa fa-user-shield fa-2x text-warning"></i>
                    <h3 class="text-warning">{{ totalArrears | toCurrencyFormat }}</h3>
                    <p class="text-uppercase mb-1 font-13 font-weight-medium">
                      Total Arrears
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="text-muted font-13 mb-4"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Actions -->
              <div class="col-sm-12 col-md-4">
                <b-button-group>
                  <b-button @click="investigateArrears(false)" variant="success">Clear Investigation</b-button>
                </b-button-group>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  ref="selectableTable"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  selectable
                  :select-mode="'multi'"
                  @filtered="onFiltered"
                  @row-selected="onRowSelected"
              >
                <template #head(checkbox)="data">
                  <b-form-checkbox v-model="selectAll" @change="selectAllHandler"/>
                </template>

                <template v-slot:cell(checkbox)="row">
                  <b-form-checkbox @click.stop v-model="row['rowSelected']"
                                   @change="onCheckBoxChanged(row)"></b-form-checkbox>
                </template>

                <template v-slot:cell(action)="data">
                  <button class="btn btn-info btn-sm" @click="getUserArrearsDetails(data.item.user_id)">Details</button>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        size="xl"
        v-model="detailsModal"
        :title="title"
        header-close-variant="light"
        title-class="font-18"
        hide-footer
    >
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label>Select week</label>
            <input type="week" class="form-control" v-model="selectedWeek" @change="getUserArrearsByWeek()">
          </div>
        </div>
      </div>

      <b-table
          responsive
          :sticky-header="true"
          table-class="table table-centered w-100"
          thead-tr-class="bg-light"
          :items="arrearsDetails"
          :fields="arrearsDetailsFields"
          ref="selectableTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          selectable
          @row-selected="onRowSelected"
      >
        <template v-slot:cell(checkbox)="row">
          <b-form-checkbox @click.stop v-model="row['rowSelected']"
                           @change="onCheckBoxChanged(row)"></b-form-checkbox>
        </template>
        <template v-slot:cell(driverUser.name)="data">
          <a target="_blank" v-if="data.item.driverUser"
             :href="'/prospective-driver-detail/' + data.item.driverUser.id">{{ data.item.driverUser.name }}</a>
          <div v-else>N/A</div>
        </template>

        <template v-slot:cell(arrearsAfterPayout)="data">
          {{ data.item.arrearsAfterPayout | toCurrencyFormat }}
        </template>

        <template v-slot:cell(arrearsBeforePayout)="data">
          {{ data.item.arrearsBeforePayout | toCurrencyFormat }}
        </template>

        <template v-slot:cell(selectedWeekTotalIncome)="data">
          {{ data.item.selectedWeekTotalIncome | toCurrencyFormat }}
        </template>

        <template v-slot:cell(selectedWeekTotalCost)="data">
          {{ data.item.selectedWeekTotalCost | toCurrencyFormat }}
        </template>

        <template v-slot:cell(transferToBank)="data">
          {{ data.item.transferToBank | toCurrencyFormat }}
        </template>

        <template v-slot:cell(selectedWeekBalance)="data">
          {{ data.item.selectedWeekBalance | toCurrencyFormat }}
        </template>

        <template v-slot:cell(weekStart)="data">
          {{ data.item.weekStart | toCurrencyFormat }}
        </template>

      </b-table>

    </b-modal>


  </div>
</template>

<script>

import {payoutService} from "../../../../apis/payout.service";
import ability from "../../../../utils/defineAbility";
import id from "vue2-datepicker/locale/es/id";

/**
 * Starter component
 */
export default {
  data() {
    return {
      detailsModal: false,
      selectAll: false,
      selectedWeek: '',
      title: '',
      arrearsDetails: [],
      arrearsDetailsFields: [
        'checkbox',
        {
          label: 'Driver',
          key: "driverUser.name",
          sortable: true,
        },
        {
          label: 'Arrears after payout',
          key: "arrearsAfterPayout",
          sortable: true,
        },
        {
          label: 'Arrears before payout',
          key: "arrearsBeforePayout",
          sortable: true,
        },
        {
          label: 'Total Income',
          key: "selectedWeekTotalIncome",
          sortable: true,
        },
        {
          label: 'Total Cost',
          key: "selectedWeekTotalCost",
          sortable: true,
        },

        {
          label: 'Transfer to Bank',
          key: "transferToBank",
          sortable: true,
        },
        {
          label: 'Week Balance',
          key: "selectedWeekBalance",
          sortable: true,
        },
        {
          label: 'Week Start',
          key: "weekStart",
          sortable: true,
        }
      ],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      selectedItems: [],
      sortBy: "age",
      sortDesc: false,
      userId: '',
      fields: [
        'checkbox',
        {
          label: 'User',
          key: "user.name",
          sortable: true,
        },
        {
          label: 'Arrears',
          key: "accumulated_amount",
          sortable: true,
        },
        "action",
      ],

    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    totalDrivers() {
      if (this.selectedItems.length > 0) {
        return this.selectedItems.length;
      }
      return this.tableData.length;
    },
    totalArrears() {
      if (this.selectedItems.length > 0) {
        return this.selectedItems.reduce((a, b) => a + (Number(b['accumulated_amount']) || 0), 0);
      }
      return this.tableData.reduce((a, b) => a + (Number(b['accumulated_amount']) || 0), 0);
    }
  },
  methods: {
    selectAllHandler() {
      if (this.selectAll) {
        this.$refs.selectableTable.selectAllRows();
      } else {
        this.$refs.selectableTable.clearSelected();
      }
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    onCheckBoxChanged(row) {
      const index = row['index']
      if (this.$refs.selectableTable.isRowSelected(index)) {
        this.$refs.selectableTable.unselectRow(index)
      } else {
        this.$refs.selectableTable.selectRow(index)
      }
    },
    onRowSelected(selectedRows) {
      this.selectedItems = selectedRows;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    investigateArrears(action) {
      console.log(action)
      if (this.selectedItems.length > 0) {
        let confirmation = confirm('Are you sure you want to perform this action?')
        if (confirmation) {
          this.investigateRequest(action);
        }
      } else {
        alert('Select arrears and perform an action!')
      }

    },
    investigateRequest(investigate) {
      this.$store.dispatch('showLoader')
      let payload = {
        arrearsIds: this.selectedItems.map(arrears => arrears.id),
        investigate
      }
      payoutService.investigateArrears(payload).then((data) => {
        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }
        this.getArrears();

      }).finally(() => {
        this.$store.dispatch('hideLoader')
      })
    },

    getArrears() {
      this.$store.dispatch('showLoader')
      payoutService.arrearsOverview(true).then((data) => {
        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }
        this.tableData = data.extra

      }).finally(() => {
        this.$store.dispatch('hideLoader')
      })
    },

    getUserArrearsDetails(userId, week = '') {
      this.userId = userId;
      this.$store.dispatch('showLoader')
      payoutService.getUserArrearsDetails(userId, week).then((data) => {
        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }

        this.arrearsDetails = data.extra['payoutDetails'];
        let driver = data.extra['driver'];
        let week = data.extra['week'];
        this.title = `${driver ? driver.name : ''} Arrears ${week ? 'for week : ' + week : ''}`;
        this.detailsModal = true;

      }).finally(() => {
        this.$store.dispatch('hideLoader')
      })
    },
    getUserArrearsByWeek() {
      this.getUserArrearsDetails(this.userId, this.selectedWeek)
    },
  },

  created() {
    this.$store.dispatch('setPageTitle', 'Investigate Arrears');
    this.getArrears();

  }
};
</script>

<style scoped>
thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
}
</style>
